import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, forwardRef, Input} from '@angular/core';
import {RadioButtonOptionModel} from '../../model/objects/radio-button-option.model';

@Component({
  selector: 'app-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonGroupComponent),
      multi: true
    }
  ]
})
export class RadioButtonGroupComponent implements ControlValueAccessor {

  @Input() name: string = 'options';
  @Input() options: RadioButtonOptionModel[] = [];
  @Input() inLine: boolean = true;

  disabled = false;
  value: any;

  propagateChange: any = () => {
  };
  touchedFn: any = () => {
  };

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectOption(option: any) {
    this.value = option.value;
    this.propagateChange(option.value);
    this.touchedFn();
  }
}
