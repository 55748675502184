import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";

@Injectable()
export class HttpService {

  private headers: HttpHeaders = new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8'
  });

  constructor(private http: HttpClient) {
  }

  public post(url: string, body: any): Observable<any> {
    return this.http.post<any>(url, body, {headers: this.headers}).pipe(catchError((res: any) => throwError(res)));
  }

  public postMultipart(url: string, body: any): Observable<any> {
    return this.http.post<any>(url, body).pipe(catchError((res: any) => throwError(res)));
  }

  public get(url: string): Observable<any> {
    return this.http.get<any>(url, {headers: this.headers}).pipe(catchError((res: any) => throwError(res)));
  }

  public getWithParams(url: string, params: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  }): Observable<any> {
    return this.http.get<any>(url, {headers: this.headers, params}).pipe(catchError((res: any) => throwError(res)));
  }

}
