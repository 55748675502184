import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EnergyEfficiencyClassEnum} from '../../model/enums/energy-efficiency-class.enum';

@Component({
  selector: 'app-energy-efficiency-upgrade',
  templateUrl: './energy-efficiency-upgrade.component.html',
  styleUrls: ['./energy-efficiency-upgrade.component.scss']
})
export class EnergyEfficiencyUpgradeComponent implements OnInit, OnChanges {

  @Input() oldEnergyClass: EnergyEfficiencyClassEnum | null = EnergyEfficiencyClassEnum.UNKNOWN;
  @Input() newEnergyClass: EnergyEfficiencyClassEnum | null = EnergyEfficiencyClassEnum.A;

  arrowPoints: string[] = [];

  arrowTop: number = 10;
  arrowLength: number = 0;
  displayArrow: boolean = false;

  ngOnInit(): void {

    this.calculateArrowPoints();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateArrowPoints();
  }

  private calculateArrowPoints(): void {

    let oldEnergyClassNumber = 0;
    let newEnergyClassNumber = 0;

    switch (this.oldEnergyClass) {
      case EnergyEfficiencyClassEnum.A:
        oldEnergyClassNumber = 1;
        break;
      case EnergyEfficiencyClassEnum.B:
        oldEnergyClassNumber = 2;
        break;
      case EnergyEfficiencyClassEnum.C:
        oldEnergyClassNumber = 3;
        break;
      case EnergyEfficiencyClassEnum.D:
        oldEnergyClassNumber = 4;
        break;
      case EnergyEfficiencyClassEnum.E:
        oldEnergyClassNumber = 5;
        break;
      case EnergyEfficiencyClassEnum.F:
        oldEnergyClassNumber = 6;
        break;
      default:
        oldEnergyClassNumber = 7;
        break;
    }

    switch (this.newEnergyClass) {
      case EnergyEfficiencyClassEnum.A:
        newEnergyClassNumber = 1;
        break;
      case EnergyEfficiencyClassEnum.B:
        newEnergyClassNumber = 2;
        break;
      case EnergyEfficiencyClassEnum.C:
        newEnergyClassNumber = 3;
        break;
      case EnergyEfficiencyClassEnum.D:
        newEnergyClassNumber = 4;
        break;
      case EnergyEfficiencyClassEnum.E:
        newEnergyClassNumber = 5;
        break;
      case EnergyEfficiencyClassEnum.F:
        newEnergyClassNumber = 6;
        break;
      default:
        newEnergyClassNumber = 7;
        break;
    }

    if (oldEnergyClassNumber - newEnergyClassNumber > 0) {
      this.displayArrow = true;
      this.arrowTop = 39 + (newEnergyClassNumber - 1) * 55;
    } else {
      this.displayArrow = false;
    }

    // this.arrowLength = (oldEnergyClassNumber - newEnergyClassNumber - 1) * 53 + (oldEnergyClassNumber - newEnergyClassNumber - 2) * 10;
    this.arrowLength = (oldEnergyClassNumber - newEnergyClassNumber - 1) * 55 + 10 * 2;

    this.arrowPoints = [];
    this.arrowPoints.push('10,20');
    this.arrowPoints.push('20,10');
    this.arrowPoints.push('30,20');
    this.arrowPoints.push('20,10');
    this.arrowPoints.push('20,' + this.arrowLength);
    this.arrowPoints.push('20,10');
  }


}
