import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-energy-efficiency-bar',
  templateUrl: './energy-efficiency-bar.component.html',
  styleUrls: ['./energy-efficiency-bar.component.scss']
})
export class EnergyEfficiencyBarComponent implements OnInit {

  @Input() width: number = 100;
  @Input() height: number = 44;
  @Input() class: string = 'A';
  @Input() text: string = '';
  @Input() text1: string = '';
  @Input() text2: string = '';
  @Input() value?: string = '< 0,53';
  @Input() color: string = 'black';
  @Input() selected: boolean = false;
  @Input() disabled: boolean = true;

  polygonPoints: string[] = [];

  ngOnInit(): void {
    //points="10,10 60,10 80,32 60,54 10,54"
    this.polygonPoints.push('10,10');
    this.polygonPoints.push(`${this.width + 10},10`);
    this.polygonPoints.push(`${this.width + 10 + 20},${(this.height / 2) + 10}`);
    this.polygonPoints.push(`${this.width + 10},${this.height + 10}`);
    this.polygonPoints.push(`10,${this.height + 10}`);

  }

  getColor(): string {
    if (this.disabled && !this.selected) {
      return '#AAAAAA';
    }
    return this.color;
  }
}
