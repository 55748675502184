<div class="tooltip" [ngClass]="tooltipVisible === true ? 'active' : ''">
  <div class="tooltip-visible d-flex flex-row align-items-center gap-2">
    <ng-content></ng-content>

    <img [ngSrc]="'assets/images/' + (inverted ? 'NEO_info_icon_inverted.svg' : 'NEO_info_icon.svg')" class="d-none d-xl-block" (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()"
         height="18" width="18">
    <img [ngSrc]="'assets/images/' + (inverted ? 'NEO_info_icon_inverted.svg' : 'NEO_info_icon.svg')" class="d-xl-none d-block" (click)="toggleTooltip()"
         height="18" width="18">
  </div>
  <div class="tooltip-text" [innerHTML]="text"></div>
</div>
