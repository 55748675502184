<div class="d-flex justify-content-start align-items-center svg-container">
  <svg [attr.width]="width + 35" [attr.height]="height + 20" style="position: absolute;">
    <polygon style="position: relative;" [attr.points]="polygonPoints.join(' ')" [attr.stroke]="getColor()"
             [attr.fill]="selected ? getColor() : 'white'" stroke-width="3" shape-rendering="geometricPrecision"/>
  </svg>
  <div class="text-container d-flex flex-row justify-content-between align-items-center gap-1 transparent-background"
       [style]="'color:' +  (selected ? 'white': getColor()) + '; width: ' + (width + 15) + 'px;'">
    <div class="d-flex flex-row justify-content-start align-items-center transparent-background" style="gap: 0.75rem">
      <div class="class-text transparent-background">
        {{ class }}
      </div>
      <div class="description-text transparent-background" style="width: 127px;">
        <div>{{text1}}</div>
        <div>{{text2}}</div>
      </div>
    </div>
  </div>
</div>
