<div class="d-flex flex-column gap-2" style="position: relative">
  <div class="d-flex justify-content-start align-items-center svg-container graph-container">
    <div *ngIf="newEnergyClass === 'A' || oldEnergyClass === 'A'"
         class="text-container d-flex flex-row justify-content-center align-items-center gap-1 transparent-background">
      <div class="d-flex flex-row justify-content-center align-items-center gap-2 transparent-background">
        <div class="class-text" [style]="'color: ' + (newEnergyClass === 'A' ? '#0B8D39' : '#AAAAAA')">A</div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-start align-items-center svg-container graph-container">
    <div *ngIf="newEnergyClass === 'B' || oldEnergyClass === 'B'"
         class="text-container d-flex flex-row justify-content-center align-items-center gap-1 transparent-background">
      <div class="d-flex flex-row justify-content-center align-items-center gap-2 transparent-background">
        <div class="class-text" [style]="'color: ' + (newEnergyClass === 'B' ? '#2AA82E' : '#AAAAAA')">B</div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-start align-items-center svg-container graph-container">
    <div *ngIf="newEnergyClass === 'C' || oldEnergyClass === 'C'"
         class="text-container d-flex flex-row justify-content-center align-items-center gap-1 transparent-background">
      <div class="d-flex flex-row justify-content-center align-items-center gap-2 transparent-background">
        <div class="class-text" [style]="'color: ' + (newEnergyClass === 'C' ? '#85C637' : '#AAAAAA')">C</div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-start align-items-center svg-container graph-container">
    <div *ngIf="newEnergyClass === 'D' || oldEnergyClass === 'D'"
         class="text-container d-flex flex-row justify-content-center align-items-center gap-1 transparent-background">
      <div class="d-flex flex-row justify-content-center align-items-center gap-2 transparent-background">
        <div class="class-text" [style]="'color: ' + (newEnergyClass === 'D' ? '#DEB505' : '#AAAAAA')">D</div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-start align-items-center svg-container graph-container">
    <div *ngIf="newEnergyClass === 'E' || oldEnergyClass === 'E'"
         class="text-container d-flex flex-row justify-content-center align-items-center gap-1 transparent-background">
      <div class="d-flex flex-row justify-content-center align-items-center gap-2 transparent-background">
        <div class="class-text" [style]="'color: ' + (newEnergyClass === 'E' ? '#F7941F' : '#AAAAAA')">E</div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-start align-items-center svg-container graph-container">
    <div *ngIf="newEnergyClass === 'F' || oldEnergyClass === 'F'"
         class="text-container d-flex flex-row justify-content-center align-items-center gap-1 transparent-background">
      <div class="d-flex flex-row justify-content-center align-items-center gap-2 transparent-background">
        <div class="class-text" [style]="'color: ' + (newEnergyClass === 'F' ? '#F15521' : '#AAAAAA')">F</div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-start align-items-center svg-container graph-container">
    <div *ngIf="newEnergyClass === 'G' || oldEnergyClass === 'G'"
         class="text-container d-flex flex-row justify-content-center align-items-center gap-1 transparent-background">
      <div class="d-flex flex-row justify-content-center align-items-center gap-2 transparent-background">
        <div class="class-text" [style]="'color: ' + (newEnergyClass === 'G' ? '#D42027' : '#AAAAAA')">G</div>
      </div>
    </div>
  </div>


  <div *ngIf="displayArrow" [style]="'position: absolute; top: ' + arrowTop + 'px; right: 2.05rem; z-index: 10'">
    <svg [attr.width]="40" [attr.height]="arrowLength + 20" style="position: absolute;">
      <polygon style="position: relative;" [attr.points]="arrowPoints.join(' ')" stroke="#a1a1a1" fill="#a1a1a1"
               shape-rendering="geometricPrecision"/>
    </svg>
  </div>
</div>
