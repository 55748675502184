<div class="overlay" [hidden]="!isLoading">
  <div class="loader">
    <svg width="150" height="150" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
      <circle cx="75" cy="75" r="60" stroke="#fafafa" stroke-width="8" fill="none">
        <animate attributeName="stroke-dasharray" attributeType="XML" from="0 376.991" to="753.982 0" dur="4s" repeatCount="indefinite"/>
        <animateTransform attributeName="transform" type="rotate" from="0 75 75" to="360 75 75" dur="4s" repeatCount="indefinite"/>
      </circle>
    </svg>
  </div>
</div>
