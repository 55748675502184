export enum EnergyEfficiencyClassEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
  UNKNOWN = 'UNKNOWN',

}
