import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from '../service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  isLoading: boolean = false;

  constructor(private loaderService: LoaderService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    // Subscribe to loading state changes
    this.loaderService.isLoading.subscribe((loading: boolean) => {
      this.isLoading = loading;

      // Manually mark for change detection after updating the isLoading property
      this.cdr.detectChanges();
    });
  }
}
