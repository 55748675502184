<div class="info-button-group"
     [ngClass]="{'inline-grid': inLine}">
  <div class="d-flex gap-1" *ngFor="let option of options">
    <div class="d-flex align-items-baseline">
      <input [id]="option.id" [name]="name" type="radio" [value]="option.value" [checked]="value === option.value"
             (change)="selectOption(option)">
    </div>
    <label [for]="option.id" [ngClass]="{'bold': value === option.value}">{{ option.label }}</label>
  </div>
</div>
