<ng-container *ngIf="errors.length > 0">
  <div class="d-flex flex-column gap-2 error-box table-max-width mb-5">
    <div class="bold">Něco je špatně!</div>
    <ul>
      <li *ngFor="let item of errors">
        <div class="italic"  [innerHTML]="item"></div>
      </li>
    </ul>
  </div>
</ng-container>
