import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EnergyEfficiencyBarComponent} from './components/energy-efficiency-bar/energy-efficiency-bar.component';
import {InfoTooltipComponent} from './components/info-tooltip/info-tooltip.component';
import {NgClass, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {MatDialogClose} from '@angular/material/dialog';
import {
  EnergyEfficiencyUpgradeComponent
} from './components/energy-efficiency-upgrade/energy-efficiency-upgrade.component';
import {LoaderComponent} from './loader/loader.component';
import {RadioButtonGroupComponent} from './components/radio-button-group/radio-button-group.component';
import {ErrorListComponent} from './components/error-list/error-list.component';

@NgModule({
  declarations: [
    EnergyEfficiencyBarComponent,
    InfoTooltipComponent,
    ConfirmDialogComponent,
    EnergyEfficiencyUpgradeComponent,
    LoaderComponent,
    RadioButtonGroupComponent,
    ErrorListComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatDialogClose,
    NgIf,
    NgOptimizedImage,
    NgForOf
  ],
  exports: [
    EnergyEfficiencyBarComponent,
    InfoTooltipComponent,
    ConfirmDialogComponent,
    EnergyEfficiencyUpgradeComponent,
    LoaderComponent,
    RadioButtonGroupComponent,
    ErrorListComponent
  ],
  providers: [
  ]
})
export class SharedModule {

}
