import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss']
})
export class InfoTooltipComponent {
  @Input() text: string = 'Tooltip text';
  @Input() inverted: boolean = false;
  tooltipVisible: boolean = false;

  showTooltip() {
    this.tooltipVisible = true;
  }

  hideTooltip() {
    this.tooltipVisible = false;
  }

  toggleTooltip() {
    this.tooltipVisible = !this.tooltipVisible;
  }
}
