<div class="dialog-container">
  <div class="mat-dialog-content">
    <p>
      {{ data?.message }}
    </p>
    <div class="mat-dialog-actions d-flex flex-row justify-content-end gap-2">
      <button class="btn btn-back light dialog-button" [mat-dialog-close]="cancel">{{ data?.cancel }}</button>
      <button class="btn neo-btn-success bold dialog-button" [mat-dialog-close]="confirm" cdkFocusInitial>{{ data?.confirm }}</button>
    </div>
  </div>
</div>
