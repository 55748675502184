import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-error-list',
  templateUrl: './error-list.component.html'
})
export class ErrorListComponent {

  @Input() errors: string[] = [];
}
