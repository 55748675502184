import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogResponseEnum} from '../../model/enums/dialog-response.enum';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  /**
   * Get response data for retry button
   */
  public get confirm() {
    return DialogResponseEnum.CONFIRM;
  }

  /**
   * Get response data for cancel button
   */
  public get cancel() {
    return DialogResponseEnum.CANCEL;
  }

}
